import React from 'react';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Text, { FontStyle } from '@webAtoms/Text/Text';
import Container from '@webAtoms/Container/Container';
import Carousel from '@webOrganisms/Carousel/Carousel';
import * as styles from './testimonials.module.scss';
import * as Route from '@routes';
import { Link } from 'gatsby';
import Featured from '@localTypes/Featured';
import { GatsbyImage } from 'gatsby-plugin-image';

export interface TestimonialsProps {
  featured: Featured[];
}

const Testimonials: React.FC<TestimonialsProps> = ({ featured }) => (
  <Box
    paddingTop={Spacing.L}
    paddingBottom={Spacing.L}
    className={styles.testimonials}
  >
    <Container>
      <Box className={styles.testimonialsInner}>
        <Box className={styles.testimonialsColumnDescription}>
          <Text semantic="h2" fontStyle={FontStyle.Hero}>
            <Link to={Route.featuredBuilds()}>Testimonials</Link>
          </Text>
          {/* <Box className={styles.testimonialsColumnDescriptionTags}>
            <Text fontStyle={FontStyle.BodyXLarge}>
              <Box htmlContent={body} />
            </Text>
          </Box> */}
        </Box>
        <Box className={styles.testimonialsColumnSlider}>
          <Box className={styles.testimonialsColumnSliderInner}>
            <Carousel
              type="articles"
              slides={featured.map(f => ({
                slide: <TestimonialBuild featuredBuild={f} />,
              }))}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  </Box>
);

const TestimonialBuild = ({ featuredBuild }: { featuredBuild: Featured }) => {
  return (
    <Box className={styles.testimonial}>
      <a
        className={styles.testimonialImage}
        href={Route.featuredBuilds(featuredBuild.slug)}
      >
        <GatsbyImage
          loading="lazy"
          alt={featuredBuild.title}
          image={featuredBuild.image}
        />
      </a>
      <a
        className={styles.testimonialBody}
        href={Route.featuredBuilds(featuredBuild.slug)}
      >
        <blockquote>{featuredBuild.testimonial}</blockquote>

        <span className={styles.testimonialOwner}>
          {featuredBuild.title}
          {Boolean(featuredBuild.owner) && (
            <span>
              &nbsp;&mdash;&nbsp;
              {featuredBuild.owner}
            </span>
          )}
        </span>
      </a>
    </Box>
  );
};

export default Testimonials;
