import React from 'react';
import Icon from '@webAtoms/Icon/Icon';
import Blog from '@localTypes/Blog';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import * as styles from './cardJournal.module.scss';
import * as Route from '@routes';

export type CardJournalProps = {
  blog: Blog;
};

const CardJournal: React.FC<CardJournalProps> = ({ blog }) => {
  return (
    <Link
      to={Route.journal(blog.slug)}
      key={blog.slug}
      className={styles.cardJournal}
    >
      <div className={styles.cardJournalImageWrapper}>
        {blog.thumb ? (
          <GatsbyImage
            key={blog.slug}
            image={blog.thumb}
            loading="lazy"
            className={styles.cardJournalImageWrapperGatsby}
            alt={blog.title}
          />
        ) : blog.video ? (
          <div className={styles.cardJournalImageWrapperYoutubePoster}>
            <img
              src={`https://i3.ytimg.com/vi/${blog.video}/hqdefault.jpg`}
              alt={blog.title}
            />
            <Icon
              name="youtube"
              className={styles.cardJournalImageWrapperYoutubePosterPlay}
            />
          </div>
        ) : null}
        <div className={styles.cardJournalImageWrapperDate}>
          {blog.date
            .toLocaleDateString('en-GB', {
              month: 'short',
              year: 'numeric',
            })
            .split(' ')
            .join(', ')}
        </div>
      </div>
      <div className={styles.cardJournalTitle}>{blog.title}</div>
      <div className={styles.cardJournalTags}>
        {blog.tags.map(({ key, label }, index) => (
          <span key={key}>
            {index > 0 && <span>&bull;</span>} #{label}
          </span>
        ))}
      </div>
    </Link>
  );
};

export default CardJournal;
