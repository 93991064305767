import React from 'react';
import Container from '@webAtoms/Container/Container';
import Box, { Spacing, Align } from '@webAtoms/Box/Box';
import Text, { FontStyle } from '@webAtoms/Text/Text';
import TextBox from '@webMolecules/TextBox/TextBox';
import Grid from '@webAtoms/Grid/Grid';
import ReadMoreInline from '@webOrganisms/ReadMoreInline/ReadMoreInline';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';
import PageHeading from '@webMolecules/PageHeading/PageHeading';
import * as styles from './tiles.module.scss';
import { stripParagraphs } from '@helpers/markdown';
import Button from '@webAtoms/Button/Button';
import YoutubeEmbed from '@webAtoms/YoutubeEmbed/YoutubeEmbed';

export interface TilesProps {
  tiles: TileProps[];
}

const Tiles: React.FC<TilesProps> = ({ tiles }) => {
  return (
    <Container>
      <Box className={styles.grid}>
        {tiles.map((item, index) => (
          <TilesItem key={index} {...item} />
        ))}
      </Box>
    </Container>
  );
};

export interface TileProps {
  name: string;
  image: IGatsbyImageData;
  href: string;
}

const TilesItem: React.FC<TileProps> = ({ name, image, href }) => {
  return (
    <nav className={styles.gridItem}>
      <span className={styles.gridItemImage}>
        <GatsbyImage
          image={image}
          alt={name}
          objectFit="cover"
          objectPosition="center center"
        />
      </span>
      <a className={styles.gridItemName} href={href}>
        {name}
      </a>
    </nav>
  );
};

export default Tiles;
