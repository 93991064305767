import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import decodeBlogs from '@decoders/Blogs';
import LatestBlogs from '@webOrganisms/LatestBlogs/LatestBlogs';

interface Props {
  exclude?: string;
}

export default ({ exclude }: Props) => (
  <StaticQuery
    query={query}
    render={data => {
      const currentDate = new Date();
      const blogs = decodeBlogs(data?.blogs?.edges || [])
        .filter(
          blog =>
            blog.date.getTime() < currentDate.getTime() &&
            (exclude === undefined || blog.slug !== exclude)
        )
        .slice(0, 10);

      const title = data?.journal?.frontmatter?.title || '';

      const props = {
        title,
        body: data?.journal?.html || '',
        blogs,
      };

      return <LatestBlogs {...props} />;
    }}
  />
);

export const query = graphql`
  query SectionLatestBlogs {
    journal: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "journal" } }
    ) {
      html
      frontmatter {
        title
      }
    }

    blogs: allMarkdownRemark(
      filter: { fields: { collection: { eq: "blog" } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 12
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "YYYY/MM/DD")
            video
            tags
            thumb {
              publicURL
              childImageSharp {
                gatsbyImageData(
                  height: 340
                  width: 392
                  # placeholder: BLURRED
                  # formats: [AUTO, WEBP, JPG]
                  transformOptions: { cropFocus: CENTER }
                )
              }
            }
          }
        }
      }
    }
  }
`;
