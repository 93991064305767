import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { getImage, IGatsbyImageData, ImageDataLike } from 'gatsby-plugin-image';
import { PageHomeQuery } from '@generated/GraphqlTypes';
import Base from '@webLayouts/Base/Base';
import Home from '@webPages/Home/Home';
import decodeDesignWithFloorPlans from '@decoders/DesignWithFloorPlans';
import DesignWithFloorPlans from '@localTypes/DesignWithFloorPlans';

const PageHome: React.FC<PageProps<PageHomeQuery>> = ({ data }) => {
  const title = data?.home?.frontmatter?.title || '';

  const seoTitle =
    typeof window !== 'undefined' &&
    window.location.search.includes('book-a-display-visit')
      ? 'Visit the Eco-Friendly Modular Display Homes | Ecoliv'
      : data?.home?.frontmatter?.seoTitle || title || undefined;

  const seoDescription =
    data?.home?.frontmatter?.seoDescription || data?.home?.excerpt || undefined;

  const hero = {
    heroVideo: data?.home?.frontmatter?.heroVideo ?? undefined,
    heroImage:
      data?.home?.frontmatter?.image ?? undefined
        ? getImage(data?.home?.frontmatter?.image as any)
        : undefined,
  };

  const intro = {
    title,
    subTitle: data?.home?.frontmatter?.subTitle ?? '',
    body: data?.home?.html ?? '',
  };

  const tiles = {
    tiles:
      data?.home?.frontmatter?.tiles?.map(tile => ({
        name: tile?.name ?? '',
        href: tile?.href ?? '',
        image: getImage(tile?.image as any) as IGatsbyImageData,
      })) || [],
  };

  return (
    <Base
      theme="light-home"
      menuBorder={false}
      title={seoTitle}
      description={seoDescription}
      image={data?.home?.frontmatter?.image?.publicURL ?? undefined}
    >
      <Home hero={hero} intro={intro} tiles={tiles} />
    </Base>
  );
};

export default PageHome;

export const query = graphql`
  query PageHome {
    home: markdownRemark(
      fields: { collection: { eq: "pages" } }
      frontmatter: { page: { eq: "home" } }
    ) {
      html
      excerpt
      frontmatter {
        title
        subTitle

        seoTitle
        seoDescription

        heroVideo

        image {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 760
              # placeholder: BLURRED
              # quality: 70
              # formats: [AUTO, WEBP, AVIF, JPG]
            )
          }
        }

        tiles {
          name
          href
          image {
            childImageSharp {
              gatsbyImageData(
                width: 760
                # placeholder: BLURRED
                # quality: 70
                # formats: [AUTO, WEBP, AVIF, JPG]
              )
            }
          }
        }
      }
    }
  }
`;
