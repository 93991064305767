import React from 'react';
import Box, { Spacing } from '@webAtoms/Box/Box';
import Text, { FontStyle } from '@webAtoms/Text/Text';
import Container from '@webAtoms/Container/Container';
import Carousel from '@webOrganisms/Carousel/Carousel';
import CardJournal from '@webMolecules/Card/CardJournal';
import * as styles from './latestBlogs.module.scss';
import * as Route from '@routes';
import Blog from '@localTypes/Blog';
import { Link } from 'gatsby';

export interface LatestBlogsProps {
  title: string;
  body: string;
  blogs: Blog[];
}

const LatestBlogs: React.FC<LatestBlogsProps> = ({ title, body, blogs }) => (
  <Box
    paddingTop={Spacing.L}
    paddingBottom={Spacing.L}
    className={styles.journal}
  >
    <Container>
      <Box className={styles.journalInner}>
        <Box className={styles.journalColumnDescription}>
          <Text semantic="h2" fontStyle={FontStyle.Hero}>
            <Link to={Route.journal()}>{title}</Link>
          </Text>
          <Box className={styles.journalColumnDescriptionTags}>
            <Text fontStyle={FontStyle.BodyXLarge}>
              <Box htmlContent={body} />
            </Text>
          </Box>
        </Box>
        <Box className={styles.journalColumnSlider}>
          <Box className={styles.journalColumnSliderInner}>
            <Carousel
              type="articles"
              slides={blogs.map(blog => ({
                slide: <CardJournal blog={blog} />,
              }))}
            />
          </Box>
        </Box>
      </Box>
    </Container>
  </Box>
);

export default LatestBlogs;
