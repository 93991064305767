import React from 'react';
import Hero, { HeroProps } from './Sections/Hero';
import EcoModularHomes, {
  EcoModularHomesProps,
} from './Sections/EcoModularHomes';
import VisitOurDisplay from '@webSections/VisitOurDisplay';
import FeaturedBuilds from '@webSections/FeaturedBuilds';
import LatestBlogs from '@webSections/LatestBlogs';
import BrochureForm from '@webSections/BrochureForm';
import UpNext from '@webMolecules/UpNext/UpNext';
import * as Route from '@routes';
import { openBookDisplayVisit } from '@helpers/calendly';
import Tiles, { TilesProps } from './Sections/Tiles';
import Intro, { IntroProps } from './Sections/Intro';
import Testimonials from '@webSections/Testimonials';

export interface HomeProps {
  hero: HeroProps;
  intro: IntroProps;
  tiles: TilesProps;
}

const Home: React.FC<HomeProps> = ({ hero, intro, tiles }) => {
  const search = typeof window !== 'undefined' ? window.location.search : '';

  React.useEffect(() => {
    try {
      if (search.includes('book-a-display-visit')) {
        openBookDisplayVisit();
      }
    } catch (e) {}
  }, [search]);

  return (
    <>
      <Hero {...hero} />
      <Intro {...intro} />
      <Tiles {...tiles} />

      <Testimonials />

      <LatestBlogs />
    </>
  );
};

export default Home;
