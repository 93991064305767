import React from 'react';
import Box from '@webAtoms/Box/Box';
import * as styles from './hero.module.scss';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

export interface HeroProps {
  heroVideo?: string;
  heroImage?: IGatsbyImageData;
}

const Hero: React.FC<HeroProps> = ({ heroVideo, heroImage }) => {
  if (heroImage) {
    return <HeroImage heroImage={heroImage} />;
  }
  return <HeroVideo heroVideo={heroVideo ?? '7k7wEAN4esk'} />;
};

const makeVideoEmbedUrl = (videoId: string) => {
  if (videoId.startsWith('https://')) {
    return videoId;
  }
  return `https://www.youtube.com/embed/${videoId}?autoplay=1&hd=1&mute=1&modestbranding=1&playsinline=1&controls=0&rel=0&loop=1&playlist=${videoId}`;
};

const HeroVideo = ({ heroVideo }: { heroVideo: string }) => {
  return (
    <Box className={styles.hero}>
      <Box className={styles.heroVideoWrapper}>
        <iframe
          src={makeVideoEmbedUrl(heroVideo)}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Box>
    </Box>
  );
};

const HeroImage = ({ heroImage }: { heroImage: IGatsbyImageData }) => {
  return (
    <Box className={styles.hero}>
      <Box className={styles.heroImageWrapper}>
        <GatsbyImage image={heroImage} alt="Ecoliv" />
      </Box>
    </Box>
  );
};

export default Hero;
