// extracted by mini-css-extract-plugin
export var testimonial = "testimonials-module--testimonial--sFMRX";
export var testimonialBody = "testimonials-module--testimonialBody--VeUBs";
export var testimonialImage = "testimonials-module--testimonialImage--93ROy";
export var testimonialOwner = "testimonials-module--testimonialOwner--WwQ3I";
export var testimonials = "testimonials-module--testimonials--fmuAE";
export var testimonialsArticleContent = "testimonials-module--testimonialsArticleContent--V1Rzq";
export var testimonialsColumnDescription = "testimonials-module--testimonialsColumnDescription--AkrWu";
export var testimonialsColumnDescriptionTags = "testimonials-module--testimonialsColumnDescriptionTags--sodHQ";
export var testimonialsColumnSlider = "testimonials-module--testimonialsColumnSlider--rPuRJ";
export var testimonialsColumnSliderInner = "testimonials-module--testimonialsColumnSliderInner--btn1F";
export var testimonialsInner = "testimonials-module--testimonialsInner--jA1E0";