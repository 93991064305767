import Featured from '@localTypes/Featured';
import slug from '@helpers/slug';
import { getImage, ImageDataLike } from 'gatsby-plugin-image';

const decodeFeatured = (edges: any = []): Featured[] => {
  const featured: Featured[] = [];
  if (!edges.length) {
    return featured;
  }

  edges.forEach((edge: any) => {
    const image = edge.node?.frontmatter?.thumb
      ? getImage(edge.node.frontmatter.thumb as ImageDataLike)
      : undefined;
    const title = edge.node?.frontmatter?.title || '';
    const tags = (edge.node?.frontmatter?.tags || []).filter(
      (a: any) => !!a
    ) as string[];
    const hideOnIndex = edge.node?.frontmatter?.hideOnIndex ?? false;
    const date = edge.node?.frontmatter?.date
      ? new Date(edge.node.frontmatter.date)
      : undefined;
    const testimonial = edge.node?.frontmatter?.testimonial || '';
    const owner = edge.node?.frontmatter?.owner || '';

    if (image && title) {
      featured.push({
        image,
        title,
        slug: slug(title),
        tags,
        hideOnIndex,
        date,
        testimonial,
        owner,
      });
    }
  });
  return featured;
};

export default decodeFeatured;
